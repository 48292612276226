<template>
    <CModal
      :title="title+' ( '+(form.code)+' )'"
      :color="color"
      :show.sync="showModal"
      :keyboard="false"
    >
        <CForm id="document_form" method="POST" enctype="multipart/form-data">
          <CInput
            hidden
            :lazy="false"
            :value.sync="$v.form.id.$model"
            name="id"
            id="id"          
          />
          <CInput
            hidden
            :lazy="false"
            :value.sync="$v.form.code.$model"
            name="code"
            id="code"          
          />
            <CRow>
              <CCol md="12" v-if="form.image" style="text-align: center;">
                <div style="text-align: center; border: 1px solid; padding: 10px;">
                  <img :src="form.image" width="300">          
                </div>
                <br />
              </CCol>
              <CCol md="6" v-if="isEdit || form.asset_category_id > 0">
                <CSelect
                    label="Categoria:"
                    name="asset_category_id"
                    id="asset_category_id"
                    :value.sync="$v.form.asset_category_id.$model"
                    :isValid="checkIfValid('asset_category_id')"
                    :options="categoriesOptions"
                    invalidFeedback="Este campo es requerido."
                    :readonly="!isEdit"
                  />                            
              </CCol>
              <CCol md="6" v-if="isEdit || form.asset_condition_id > 0">
                <CSelect
                  label="Condición:"
                  name="asset_condition_id"
                  id="asset_condition_id"
                  :value.sync="$v.form.asset_condition_id.$model"
                  :isValid="checkIfValid('asset_condition_id')"
                  :options="conditionsOptions"
                  invalidFeedback="Este campo es requerido."
                  :readonly="!isEdit"
                />                              
              </CCol>    
              <CCol md="12" v-if="isEdit || form.name != ''">
                <CInput
                  label="Nombre:"
                  name="name"
                  id="name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  :readonly="!isEdit"
                />
              </CCol>
              <CCol md="8" v-if="isEdit || form.description != ''">
                <CInput
                  label="Descripción:"
                  name="description"
                  id="description"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                  autocomplete="given-description"
                  :readonly="!isEdit"
                />
              </CCol>  
              <CCol md="4" v-if="isEdit || form.serie != ''">
                <CInput
                  label="Serie:"
                  name="serie"
                  id="serie"
                  :lazy="false"
                  :value.sync="$v.form.serie.$model"
                  placeholder=""
                  autocomplete="given-description"
                  :readonly="!isEdit"
                />
              </CCol>      
            </CRow>
            <CRow>         
              <CCol md="6" v-if="isEdit || form.asset_location_id > 0">
                <CSelect
                  label="Lugar:"
                  name="asset_location_id"
                  id="asset_location_id"
                  :value.sync="$v.form.asset_location_id.$model"
                  :isValid="checkIfValid('asset_location_id')"
                  :options="locationsOptions"
                  invalidFeedback="Este campo es requerido."
                  :readonly="!isEdit"
                />                        
              </CCol>       
              <CCol md="6" v-if="isEdit || form.asset_departament_id > 0">
                <CSelect
                  label="Departamento:"
                  name="asset_departament_id"
                  id="asset_departament_id"
                  :value.sync="$v.form.asset_departament_id.$model"
                  :isValid="checkIfValid('asset_departament_id')"
                  :options="departamentsOptions"
                  invalidFeedback="Este campo es requerido."
                  :readonly="!isEdit"
                />                              
              </CCol>                          
              <CCol md="12" v-if="isEdit">
                <CInput
                  label="Imagen:"
                  :lazy="false"
                  :value.sync="$v.form.upload_image.$model"
                  type="file"
                  accept=".jpg,.jpeg,.png;capture=camera"
                  name="upload_image"
                  id="upload_image"
                  placeholder=""
                  autocomplete="given-image"
                />
              </CCol>     
            </CRow>
        </CForm>
        <template #footer>
          <CButton class="exit" @click="store" color="danger" v-show="isEdit">Salir</CButton>
          <CButton @click="store" :color="color" v-show="isEdit">Guardar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, minValue, helpers } from "vuelidate/lib/validators"
import ws_cat from '../../services/asset_categories';
import ws_con from '../../services/asset_conditions';
import ws_dep from '../../services/asset_departaments';
import ws_loc from '../../services/asset_locations';
import ws_per from '../../services/asset_persons';

export default {
  name: 'AssetModal',
  props: {
    username: String
  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Activo",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,      
      categoriesOptions: [],
      departamentsOptions: [],
      conditionsOptions: [],
      personsOptions: [],
      locationsOptions: [],
      qr: false,
      static: 'static'
    }
  },
  mounted: async function() {    
    let response_cat = await ws_cat.getPublic();
    if(response_cat.type == "success"){
      this.categoriesOptions = await this.$parseSelectOptionsOrdered(response_cat.data, "id", "name",[]);
    }
    let response_dep = await ws_dep.getPublic();
    if(response_dep.type == "success"){
      this.departamentsOptions = await this.$parseSelectOptionsOrdered(response_dep.data, "id", "name",[]);
    }
    let response_con = await ws_con.getPublic();
    if(response_con.type == "success"){
      this.conditionsOptions = await this.$parseSelectOptionsOrdered(response_con.data, "id", "name",[]);
    }
    let response_loc = await ws_loc.getPublic();
    if(response_loc.type == "success"){
      this.locationsOptions = await this.$parseSelectOptionsOrdered(response_loc.data, "id", "name",[]);
    }

    let response_per = await ws_per.getPublic();
    if(response_per.type == "success"){
      this.personsOptions = await this.$parseSelectOptionsOrdered(response_per.data, "id", "name",[]);
    }

  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Activo';
      this.isEdit = false;
      this.submitted = false;

      let category_id = "";
      this.categoriesOptions.forEach(async (object) => {
        if(object.is_default){
          category_id = object.value;
        }
      });

      let condition_id = "";
      this.conditionsOptions.forEach(async (object) => {
        if(object.is_default){
          condition_id = object.value;
        }
      });

      let departament_id = "";
      this.departamentsOptions.forEach(async (object) => {
        if(object.is_default){
          departament_id = object.value;
        }
      });

      let location_id = "";
      this.locationsOptions.forEach(async (object) => {
        if(object.is_default){
          location_id = object.value;
        }
      });

      this.form = {
          id: 0,
          name: '',
          description: '',
          serie: "",
          asset_category_id: category_id,
          asset_departament_id: departament_id,
          asset_condition_id: condition_id,
          asset_location_id: location_id,
          image: "",
          upload_image: ""
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Activo';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          description: item.description,
          serie: item.serie,
          asset_category_id: item.asset_category_id,
          asset_departament_id: item.asset_departament_id,
          asset_condition_id: item.asset_condition_id,
          asset_person_id: item.asset_person_id,
          asset_location_id: item.asset_location_id,
          image: item.image,
          upload_image: ""
      };
    },
    qrModal (item) {
      this.showModal = true;
      this.color = "info";

      this.code = item.code;

      this.isEdit = this.username ? true : false;

      if(item.id > 0){
        this.title = 'Información del Activo';

        this.form = {
          id: item.id,
          code: item.code,
          name: item.name,
          description: item.description,
          serie: item.serie,
          asset_category_id: item.asset_category_id,
          asset_departament_id: item.asset_departament_id,
          asset_condition_id: item.asset_condition_id,
          asset_person_id: item.asset_person_id,
          asset_location_id: item.asset_location_id,
          image: item.image,
          upload_image: ""
        };
      }
      else{
          if(!this.isEdit){
            window.location.href = "/#/login"; return;
          }

          this.title = 'Nuevo Activo';

          let category_id = this.categoriesOptions[0].value;
          this.categoriesOptions.forEach(async (object) => {
            if(object.is_default){
              category_id = object.value;
            }
          });

          let condition_id = this.conditionsOptions[0].value;
          this.conditionsOptions.forEach(async (object) => {
            if(object.is_default){
              condition_id = object.value;
            }
          });

          let departament_id = this.departamentsOptions[0].value;
          this.departamentsOptions.forEach(async (object) => {
            if(object.is_default){
              departament_id = object.value;
            }
          });

          let location_id = this.locationsOptions[0].value;
          this.locationsOptions.forEach(async (object) => {
            if(object.is_default){
              location_id = object.value;
            }
          });

          this.form = {
            id: 0,
            code: item.code,
            name: '',
            description: '',
            serie: "",
            asset_category_id: category_id,
            asset_departament_id: departament_id,
            asset_condition_id: condition_id,
            asset_location_id: location_id,
            image: "",
            upload_image: ""
          }
      }
      
      
      this.submitted = false;

      this.qr = true;
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let form = document.getElementById("document_form");
        let formData = new FormData(form);


        if(this.form.id > 0){
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        //this.closeModal();
      }
    },
    refreshItem (item) {
      this.form.id = item.id;
      this.form.image = item.image;
      this.title = 'Información del Activo';
    },
    preventClose(event) {
      event.preventDefault();
    },
    closeModal () {
      // this.showModal = false;

      // this.form = this.getEmptyForm()
      // this.submitted = false
      // this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          description: '',
          serie: "",
          asset_category_id: "",
          asset_departament_id: "",
          asset_condition_id: "",
          asset_location_id: "",
          image: "",
          upload_image: ""
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      id: {},
      code: {},
      image: {},
      upload_image: {},
      name: {
        required,
        minLength: minLength(3)
      },
      description: {},
      serie: {},
      asset_category_id: {
        required,
        minValue: minValue(1)
      },
      asset_location_id: {
        required,
        minValue: minValue(1)
      },
      asset_departament_id: {
        required,
        minValue: minValue(1)
      },
      asset_condition_id: {
        required,
        minValue: minValue(1)
      },
    }
  },
}
</script>
<style>
  .close{
    display: none;
  }

  .exit{
    float: left;
  }
</style>