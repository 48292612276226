<template>
  <div>
    <CRow>
      <CCol sm="12"></CCol>
    </CRow>

    <AssetModal ref="addModal" :username="user_name" @store="storeProcess" @update="updateProcess"></AssetModal>
    <DeleteModal ref="deleteModal" @confirm="deleteProcess"></DeleteModal>

    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import AssetModal from '../../components/assets/ShowAssetModal.vue';
import DeleteModal from '../../components/global/ConfirmModal.vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';

import ws from '../../services/assets';
import account_ws from '../../services/account';

import store from '../../store'

export default {
  name: 'ShowAsset',
  components: { AssetModal, DeleteModal, Loading },
  data: function () {
		return {
            asset: false,
            qr: false,
            error: false,
            name: "",
            user_name: "",
            mobile: false
        }
  },
  mounted: async function() {
    this.loading();

    if(this.$isMobile()){
      this.mobile = true;
    }
    else{
      localStorage.setItem("next_url", "/"+window.location.hash);
      window.location.href = "/#/home"; return;
    }
    

    let response = await account_ws.getPublicInfo(); 

    this.user_name = "";
    if(response.type == "success"){
      this.name = response.data.name;
      if(response.data.user != null){
        this.user_name = response.data.user.first_name+" "+response.data.user.last_name;
      }
    }

    this.qr = await this.$route.params.qr;

    let response2 = await ws.getPublicQrAsset(this.qr);

    if(response2.type == "success"){
      this.asset = response2.data;

      let _this = this;

      setTimeout(function(){
        _this.qrModal(_this.asset);
      },1000);
    }
    else{
      this.error = response2.message;
    }

    this.loaded();
  },
  methods: {
    qrModal (item) {
      this.$refs.addModal.qrModal(item);
    },
    assignModal (item) {
      this.$refs.assignModal.storeModal(item);
    },
    deleteModal (item) {    
      this.idToDelete = item.id;
      let color = "success";
      let title = "Reactivar Registro";
      let content = "¿Estás seguro que deseas reactivar el registro?"
      let confirm = "<span style='font-size: 16px;font-weight: bold;'>"+item.code + " - " + item.name+"</span><br /><span style='font-size: 14px;'>"+item.description+"</span>";
      if(this.tab == "active"){
        color = "danger";
        title = "Archivar Activo";
        content = "¿Estás seguro que deseas archivar el activo?"
      }
      this.$refs.deleteModal.show(color,title,content,confirm);
    },
    async storeProcess(data) {
      this.loading();
      let response = await ws.storeQR(data);

      this.showToast(response.type, response.message);
      this.loaded();

      this.$refs.addModal.refreshItem(response.data);
    },
    async updateProcess(data) {
      this.loading();
      let response = await ws.updateQR(data);

      this.showToast(response.type, response.message);
      this.loaded();

      this.$refs.addModal.refreshItem(response.data);
    },
    async deleteProcess() {
      this.loading();
      let response = await ws.delete({id: this.idToDelete});
      
      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async storeProcessAssign(data) {
      this.loading();
      let date = moment(data.date).format("YYYY-MM-DD");

      data.date = date;
      let response = await ws_assig.store(data);      
      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcessAssign(data) {
      this.loading();
      let response = await ws_assig.update(data);

      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  }
}
</script>
